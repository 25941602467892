import React from "react";
import { graphql } from "gatsby";

import CallToAction from "../components/CallToAction/CallToAction";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import MarkdownHero from "../components/Hero/MarkdownHero";
import Post from "../components/Post/Post";
import SingleImage from "../components/SingleMedia/SingleImage";


const BlogPostTemplate = ({
  data
}) => {
  const {
    markdownRemark: {
      frontmatter: {
        title,
        subtitle,
        date,
        author,
        authorLink,
        category,
        featuredImage: {
          childImageSharp: {
            fluid ,
          },
        },
        altText,
        description,
        template,
      },
      html
    }
  } = data;

  return (
    <Layout
      title={ title }
      description={ description }
    >
      <Header
        colorScheme="light"
      />

      <MarkdownHero
        title={ title }
        subtitle={ subtitle }
        author={ author }
        authorLink={ authorLink }
        template={ template }
        category={ category }
        date={ date }
        colorScheme="light"
      />

      <SingleImage
        image={ fluid }
        imageAlignment="left"
        altText={ altText }
        colorScheme="light"
      />

      <Post
        body={ html }
        date={ date }
        template={ template }
      />

      <CallToAction />

      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        author
        authorLink
        category
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        altText
        description
        template
      }
    }
  }
`;

export default BlogPostTemplate;